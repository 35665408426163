import ModalView from './ModalView'

import {
    ModalContextProvider,
    useModal,
} from './ModalContext'

export default ModalView

export {
    ModalContextProvider,
    useModal,
}